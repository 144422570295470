/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Container from '../components/Container';
import TextLink from '../components/TextLink';
import ArticleTools from '../components/ArticleTools';

export const NewsPostsTemplate = ({ latestPosts }) => (
  <Container>
    <div>
      {latestPosts.map((post, i) => (
        <Fragment key={post.id}>
          {i > 0 && (
            <hr sx={{ height: '1px', backgroundColor: 'muted', my: 9 }} />
          )}
          <div
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gridGap: 11,
            }}
          >
            <div>
              <h2>
                <TextLink
                  to={post.fields.url}
                  sx={{
                    display: 'block',

                    fontSize: '21px',
                    lineHeight: 'heading',
                    mb: 4,
                  }}
                >
                  {post.frontmatter.title}
                </TextLink>
              </h2>
              {post.frontmatter.subtitle && (
                <h3
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'heading',
                    mb: 4,
                  }}
                >
                  {post.frontmatter.subtitle}
                </h3>
              )}
              <div sx={{ display: 'flex', ml: -2 }}>
                <ArticleTools post={post} />
              </div>
            </div>
            {post.frontmatter.thumbnail && (
              <Link to={post.fields.url} title={post.frontmatter.title}>
                <Img
                  fluid={post.frontmatter.thumbnail.childImageSharp.fluid}
                  loading="eager"
                  fadeIn={false}
                />
              </Link>
            )}
          </div>
        </Fragment>
      ))}
    </div>
  </Container>
);

const NewsPosts = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
    latestPosts,
  } = data;

  return (
    <Layout
      page="news-posts"
      seo={{
        title: frontmatter.pageTitle,
        description: frontmatter.description,
      }}
    >
      <NewsPostsTemplate latestPosts={latestPosts.nodes} />
    </Layout>
  );
};

NewsPosts.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default NewsPosts;

export const pageQuery = graphql`
  query NewsPostsQuery($id: String!, $language: String!, $currentDate: Date!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        description
      }
    }
    latestPosts: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 25
      filter: {
        fields: { sourceInstanceName: { eq: "news-posts" } }
        frontmatter: {
          language: { eq: $language }
          date: { lte: $currentDate }
        }
      }
    ) {
      nodes {
        id
        slug
        fields {
          url
        }
        frontmatter {
          title
          subtitle
          type
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        timeToRead
      }
    }
  }
`;
